import React, { useEffect } from 'react';
import { render } from 'react-snapshot';
import './index.css';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import { Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
      minHeight: '100vh',
    },
    header: {
      height: theme.spacing(30),
    },
    image: {
      backgroundImage: 'url(background.jpg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    },
    wrapper: {
      padding: theme.spacing(4),
      minHeight: '100vh',
      position: 'relative'
    },
    text: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(8)
    },
    inputLabel: {
      flexGrow: 1,
    },
    calculations: {
      marginTop: theme.spacing(2)
    },
    bottomText: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      verticalAlign: 'bottom',
      fontWeight: 500
    },
    bottomImage: {
      width: '100%',
      verticalAlign: 'middle',
      marginTop: theme.spacing(3)
    },
    calculatorHeader: {
      marginBottom: theme.spacing(1),
      fontWeight: 600
    },
    calculationResults: {
      marginTop: theme.spacing(2),
    },
    footer: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: theme.spacing(1),
    }
  }));

  
  
function App() {
    const classes = useStyles();

    const [price, setPrice] = React.useState(10000),
          [extras, setExtras] = React.useState(1000),
          [years, setYears] = React.useState(5),
          [service, setService] = React.useState(500),
//          [taxableValue, setTaxableValue] = React.useState(1801), //exkl. skattelättnad
          [taxableValue, setTaxableValue] = React.useState(0), //inkl. skattelättnad
          [taxReduction, setTaxReduction] = React.useState(true);

    const round = (int) => {
      return (((Math.round(int*2)/2).toFixed(2)).toString()).replace(".", ",")
    }

    const updatePrice = (event, value) => {
      setPrice(value)
      updateTaxableValue()
    }
    const updateExtras = (event, value) => {
      setExtras(value)
      updateTaxableValue()
    }
    const updateYears = (event, value) => {
      setYears(value)
      updateTaxableValue()
    }
    const updateService = (event, value) => {
      setService(value)
      updateTaxableValue()
    }
    const updateTaxableValue = () => {
      console.log(taxReduction)
      if(taxReduction) {
        console.log(1)
        const taxCalc = Math.ceil(((price + extras)/years) + service + (price * 0.0362))
        if(taxCalc > 3000) {
          setTaxableValue((taxCalc - 3000))
        } else {
          setTaxableValue(0)
        }
      } else {
        console.log(0)
        setTaxableValue(Math.ceil(((price + extras)/years) + service + (price * 0.0362)))
      }
    }
    const updateTaxReduction = (event, value) => {
      setTaxReduction(taxReduction => !taxReduction)
      updateTaxableValue()
    }

    useEffect(() => {
      updateTaxableValue()
    }, [taxReduction])

    return (
      <Grid container component="main" className={classes.root}>
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} className={classes.wrapper} elevation={3} square>
          <Typography variant="h4" align="left" >Kalkylator för cykelförmån</Typography>
          <Typography variant="body1" align="left" className={classes.text}>Vad kul att du är intresserad av konceptet cykelförmån, det är nämligen bra för hälsan, miljön och plånboken. Vare sig du är anställd, arbetsgivare eller egenföretagre hoppas jag att du kommer finna min kalkylator användbar för din kommande cykelinvestering.<br /><br /><font style={{ fontWeight: 500 }}>Från och med den 1 januari 2022 ges en skattelättnad för förmånscyklar vilket reducerar förmånsvärdet med 3 000kr per år.</font></Typography>
          <Typography className={classes.calculatorHeader} variant="subtitle1" align="left">Beräkna förmånsvärde</Typography>
          <Grid container className={classes.calculatorRow}>
          <Grid item className={classes.inputLabel}>
            <Typography variant="subtitle2" align="left">Inköpspris (inkl. moms)</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">{round(price)} kr</Typography>
          </Grid>
          <Grid item xs={12}> 
            <Slider
                name="priceSlider"
                value={price}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="off"
                step={100}
                min={0}
                max={70000}
                onChange={updatePrice}
            />        
          </Grid>
          <Grid item className={classes.inputLabel}>
            <Typography variant="subtitle2" align="left">Tillbehör</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">{round(extras)} kr</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" align="left">
              Batterier, laddare, pakethållare, m.m.
            </Typography>
          </Grid>
          <Grid item xs={12}>  
            <Slider
                name="priceSlider"
                value={extras}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="off"
                step={50}
                min={0}
                max={15000}
                onChange={updateExtras}
            />
          </Grid>
          <Grid item className={classes.inputLabel}>
            <Typography variant="subtitle2" align="left">Antal år för avskrivning (förväntad livslängd)</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">{years} år</Typography>
          </Grid>
          <Grid item xs={12}> 
            <Slider
                name="yearsSlider"
                value={years}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="off"
                step={1}
                min={1}
                max={15}
                onChange={updateYears}
            />
          </Grid>
          <Grid item className={classes.inputLabel}>
            <Typography variant="subtitle2" align="left">Servicekostnader per år</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">{round(service)} kr</Typography>
          </Grid>
          <Grid item xs={12}> 
            <Slider
                name="serviceSlider"
                value={service}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="off"
                step={50}
                min={0}
                max={10000}
                onChange={updateService}
            />
          </Grid>
          <Grid item className={classes.inputLabel}>
            <Typography variant="subtitle2" align="left">Beräkna förmånsvärde inklusive skattelättnad <Switch checked={taxReduction} onChange={updateTaxReduction} color="primary" size="small" /></Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.calculationResults}>
            <Grid item xs={6} className={classes.inputLabel}>
              <Typography variant="subtitle2" align="left">Förmånsvärde { taxReduction ? "(inkl. skattelättnad)" : "(exkl. skattelättnad)" }</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" align="right">{round(taxableValue)} kr / år<br />{round(taxableValue/12)} kr / mån</Typography>
            </Grid>
            <Grid item xs={6} className={classes.inputLabel}>
              <Typography variant="subtitle2" align="left">Förmånsbeskattning vid 33% marginalskatt</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" align="right">{round(taxableValue * 0.33)} kr / år<br />{round((taxableValue*0.33)/12)} kr / mån</Typography>
            </Grid>
            <Grid item xs={6} className={classes.inputLabel}>
              <Typography variant="subtitle2" align="left">Förmånsbeskattning vid 50% marginalskatt</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" align="right">{round(taxableValue * 0.50)} kr / år<br />{round((taxableValue*0.50)/12)} kr / mån</Typography>
            </Grid>
            <Grid item xs={6} className={classes.inputLabel}>
              <Typography variant="subtitle2" align="left">Arbetsgivaravgift (31,42%)</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" align="right">{round(taxableValue * 0.3142)} kr / år<br />{round((taxableValue * 0.3142)/12)} kr / mån</Typography>
            </Grid>
            <Grid item><Typography align="left" variant="body2">Beräkningar är utförda enligt <Link style={{ fontWeight: 500, color: "#3f51b5", textDecoration: "none" }} target="_blank" href="https://www.skatteverket.se/privat/skatter/arbeteochinkomst/formaner/cykel.4.46ae6b26141980f1e2d466e.html">Skatteverkets räkneexempel</Link> och med <Link style={{ fontWeight: 500, color: "#3f51b5", textDecoration: "none" }} target="_blank" href="https://www.riksgalden.se/sv/var-verksamhet/statslanerantan/sa-anvands-statslanerantan/">2,62% statslåneränta</Link>.</Typography></Grid>
          </Grid>
          { /*<Typography variant="body1" align="left" className={classes.bottomText}><Link style={{ color: "#3f51b5", textDecoration: "none" }} target="_blank" href="https://track.adtraction.com/t/t?a=1238049253&as=1410644312&t=2&tk=1" >Besök gärna RevolutionRace via denna affiliatelänk för att hitta dina nya outdoor- eller pendlingskläder!</Link></Typography>
          */ 
          }
          <Typography variant="body2" align="center" className={classes.footer}>© 2024 Jonathan Sehlin - All Rights Reserved.</Typography>
        </Grid>
      </Grid>
    );
  }

render(<App />, document.getElementById('root'));